import React, { memo, useRef } from 'react';
import {
    IconButton,
} from '@material-ui/core';
import {
    Close as CloseIcon
} from '@material-ui/icons';

const CustomDrawer = memo(({children, title, isOpen, close}) => {
    const drawerHeaderRef = useRef();

    return (
        <aside
            style={{width: '395px'}}
            className={`${isOpen ? 'translate-x-0 shadow-2xl' : 'translate-x-full'} transform top-0 right-0 bg-white fixed h-full max-h-full overflow-auto ease-in-out transition-all duration-300 z-30 overflow-y-hidden`}>
            <div
                ref={drawerHeaderRef}
                className="py-4 flex justify-between items-center w-full px-6 border-b-1 border-gray-300">
                <h6 className="font-bold font-lg">{title}</h6>
                <IconButton
                    onClick={close}
                    className="outline-none"
                    aria-label="Close"><CloseIcon/></IconButton>
            </div>
            <div
                style={{height: `calc(100vh - ${drawerHeaderRef?.current?.clientHeight || 0}px)`}}
                className="w-full pb-16 overflow-y-auto">
                {children}
            </div>
        </aside>
    );
});

export default CustomDrawer;