import React, { memo } from 'react';
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogContentText,
    DialogActions,
    Button
} from '@material-ui/core';

const Alert = memo(({open, onCancel, onAccept, title, message, cancelMsg = 'No', acceptMsg = 'Yes'}) => {
    return (
        <Dialog
            open={open}
            fullWidth
            onClose={onCancel}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description">
            <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">{message}</DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={onCancel} color="primary" className="outline-none text-red-500">
                    {cancelMsg}
                </Button>
                <Button onClick={onAccept} color="primary" className="outline-none text-primaryDark">
                    {acceptMsg}
                </Button>
            </DialogActions>
        </Dialog>
    );
});

export default Alert;