const auth = {
    state: {
        isAuthenticated: false,
        isLoading: false,
        invalidLoginCredentialsError: false,
        authenticatedUser: null,
    },
    reducers: {
        setAuthenticatedUser(state, payload) {
            localStorage.setItem('fmAuth', JSON.stringify(payload));
            return {
                ...state,
                authenticatedUser: payload
            };
        },
        logout(state) {
            localStorage.removeItem('fmAuth');
            return {
                ...state,
                authenticatedUser: null
            }
        }
    }
};

export default auth;