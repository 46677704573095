import React, { memo, useState, useCallback, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import {
    IconButton,
    Tooltip
} from '@material-ui/core';
import {
    ArrowDropDown as ArrowDropDownIcon,
    ArrowLeft as ArrowLeftIcon,
    ArrowRight as ArrowRightIcon,
    FirstPage as FirstPageIcon,
    LastPage as LastPageIcon,
} from '@material-ui/icons';
import { format } from 'date-fns';
import { useOnlyNumbers } from '../../../hooks';

const today = new Date();

const CustomTable = memo(({tableSchema = [], getDataFn, loading = false, data = [], paginationData = {}, search = '', menuComponent, status, defaultRequestParams = {}, defaultOrderName, defaultOrderBy = 'ASC'}) => {
    const [ iconButtonRefs ] = useState([]);
    const [ rowRightPadding, setRowRightPadding ] = useState();
    const { paginationItemsPerPage } = useSelector((st) => st.ui);
    const [ paginationSettings, setPaginationSettings ] = useState({
        orderBy: defaultOrderBy,
        orderName: defaultOrderName || tableSchema[0].orderName,
        subOrderName: defaultOrderName ? null : tableSchema[0].subOrderName,
        page: 1,
        perPage: paginationItemsPerPage
    });
    const pageRef = useRef();
    const itemsAtPageRef = useRef();
    const { onlyNumbers } = useOnlyNumbers();
    const dispatch = useDispatch();

    const nextPage = useCallback(() => {
        if (paginationSettings.page < paginationData.totalPages) {
            pageRef.current.value = paginationSettings.page + 1;
            setPaginationSettings((st) => ({...st, page: paginationSettings.page + 1}));
        }
    }, [paginationSettings, paginationData, setPaginationSettings]);
    
    const pageBefore = useCallback(() => {
        if (paginationSettings.page > 1) {
            pageRef.current.value = paginationSettings.page - 1;
            setPaginationSettings((st) => ({...st, page: paginationSettings.page - 1}));
        }
    }, [paginationSettings, setPaginationSettings]);

    const handleOrderClick = useCallback((field) => {
        const { orderBy, orderName, subOrderName } = paginationSettings;
        field.orderName === orderName && field.subOrderName === subOrderName
            ? setPaginationSettings((st) => ({ ...st, orderBy: orderBy === 'ASC' ? 'DESC' : 'ASC', page: 1 }))
            : setPaginationSettings((st) => ({ ...st, orderBy: 'ASC', orderName: field.orderName, subOrderName: field.subOrderName,  page: 1 }));

    }, [setPaginationSettings, paginationSettings]);

    const buildUsersRequestParams = useCallback(() => {
        const orderField = tableSchema.find((field) => field.orderName === paginationSettings.orderName);
        let sort = orderField?.isRelation
            ? {
                [paginationSettings.orderName]: {
                    [paginationSettings.subOrderName || 'name']: paginationSettings.orderBy
                }
            }
            : { [paginationSettings.orderName]: paginationSettings.orderBy };

        return {
            variables: {
                sort,
                filter: search.trim(),
                page: paginationSettings.page,
                perPage: paginationSettings.perPage,
                withDeleted: false,
                status: typeof status === 'boolean' && status !== undefined ? (status ? 'BlackList' : 'WhiteList') : status,
                ...defaultRequestParams
            }
        };
    }, [paginationSettings, tableSchema, search, status, defaultRequestParams]);

    const validateRowType = useCallback((field, row) => {
        if (field.subDataKey) {
            return row[field.dataKey][field.subDataKey]
        } else if (field.getLength) {
            return row[field.dataKey]?.length;
        } else if (field.enum) {
            return field.enum[row[field.dataKey]]
        } else if (field.isDate) {
            return format(new Date(Number(row[field.dataKey])), field?.dateFormat || 'MM-dd-yyyy')
        } else if (field.isMenu) {
            return menuComponent(row);
        }

        return row[field.dataKey];
    }, [menuComponent]);

    const getFlexAlignment = useCallback((nameAlignment) => {
        if (nameAlignment === 'center') {
            return 'justify-center text-center';
        }

        return nameAlignment === 'left' ? 'justify-start text-left' : 'justify-end text-right';
    }, []);

    const getRowDataKey = useCallback((field, row, fieldIndex) => {
        if (field.link) {
            let url = field.link.baseUrl;
            field.link.paramsKeys.forEach((param) => url = url.replace(`[${param.urlKey}]`, row[param.dataKey]));
            return <p
                        key={`data${field.dataKey}`} className={`${field.widthClassName} flex ${getFlexAlignment(field.nameAlignment)} items-center`}
                        style={{paddingRight: `${field.nameAlignment === 'right' ? rowRightPadding : ''}px`}}>
                        <Link to={url} className={`text-customBlue underline text-bold ${getFlexAlignment(field.nameAlignment)}`}>{row[field.dataKey]}</Link>
                    </p>
        }

        return <Tooltip title={field?.truncate ? validateRowType(field, row) : ''}><p
                    key={`data${field.dataKey}`}
                    className={`${field.widthClassName} flex ${getFlexAlignment(field.nameAlignment)} items-center ${field.truncate && 'truncate'}`}
                    style={{paddingRight: `${field.nameAlignment === 'right' ? rowRightPadding : ''}px`}}>
                        
                        {validateRowType(field, row)} &nbsp; <strong>{field.calculatedValue ? field.calculatedValue(today, new Date(Number(row[field?.dataKey]))) : ''}</strong>
                    
                </p></Tooltip>
    }, [validateRowType, getFlexAlignment, rowRightPadding]);
    
    const getOrderIconClasses = useCallback((field) => {
        let classes = 'transform transition-transform';
        if (paginationSettings.orderBy === 'DESC' && paginationSettings.orderName === field.orderName && paginationSettings.subOrderName === field.subOrderName) {
            classes += ' rotate-180';
        }

        if ((paginationSettings.orderName !== field.orderName || paginationSettings.subOrderName !== field.subOrderName)
            || (paginationSettings.orderName !== field.orderName && paginationSettings.subOrderName !== field.subOrderName && field.subOrderName === undefined)) {
            classes += ' text-gray-300';
        }

        return classes;
    }, [paginationSettings]);

    useEffect(() => {
        getDataFn(buildUsersRequestParams());
        // eslint-disable-next-line
    }, [paginationSettings, status]);
    
    useEffect(() => {
        if (pageRef.current) {
            pageRef.current.value = 1;
            setPaginationSettings((st) => ({...st, page: 1}));
        }
        getDataFn(buildUsersRequestParams());
        // eslint-disable-next-line
    }, [search]);
    
    useEffect(() => {
        setRowRightPadding(iconButtonRefs.current.clientWidth);
        getDataFn(buildUsersRequestParams());
        
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        setPaginationSettings((st) => ({...st, perPage: paginationItemsPerPage}));
    }, [paginationItemsPerPage]);

    return (
        <div className="w-full">
            <div className="custom-table">
                <div className="border-b-1 border-customGray w-full flex justify-center items-center text-customGray mt-3 px-6">
                    {tableSchema.map((field) =>
                        <div key={field.name} className={`flex items-center ${getFlexAlignment(field.nameAlignment)} py-2 ${field.widthClassName}`}>
                            <p className="outline-none bg-bgGray capitalize text-center" style={{paddingLeft: `${field.nameAlignment === 'center' ? rowRightPadding : ''}px`}}>{field.name}</p>
                            {!field.isMenu
                                && <IconButton
                                        aria-label="asc"
                                        ref={iconButtonRefs}
                                        className="outline-none p-0"
                                        onClick={() => handleOrderClick(field)}>
                                        <ArrowDropDownIcon
                                            fontSize="inherit"
                                            className={getOrderIconClasses(field)} />
                                    </IconButton>}
                        </div>)}
                    {/* {menuComponent && <div className={`py-2 w-1/${tableSchema.length + 1}`}>
                        <p className="outline-none bg-bgGray capitalize"></p>
                    </div>} */}

                </div>
                
                <div className="w-full">
                {loading && <><div className="w-full h-10 px-6 py-3 mt-3 bg-customGray rounded-md shadow-default text-sm text-center animate-pulse"></div>
                    <div className="w-full h-10 px-6 py-3 mt-3 bg-gradient-to-b from-customGray to-transparent rounded-md shadow-default text-sm text-center animate-pulse"></div></>}
                {!loading && !data.length && <div className="w-full px-6 py-3 mt-3 bg-white rounded-md shadow-default text-sm text-center">No results</div>}
                {!loading &&
                    data.map((item, i) =>
                        <div key={`deploy${i}`} className={`${item?.inBlacklist && 'bg-gray-300'} w-full px-1 mt-2 rounded-md shadow-default flex text-xs`}>
                            {tableSchema.map((field, fieldIndex) => getRowDataKey(field, item, fieldIndex))}
                            {/* <div className={`w-1/${tableSchema.length + (menuComponent ? 1 : 0)} flex items-center justify-center`}>{menuComponent && menuComponent(item)}</div> */}
                        </div>
                )}
            </div>
            </div>
            {!loading && data.length > 0 && <div className="full flex justify-end items-center mt-4 mb-12 gap-2">
                <p>Page</p>
                <IconButton
                    className="outline-none text-accent2 -mx-2"
                    onClick={() => {
                        pageRef.current.value = 1;
                        setPaginationSettings((st) => ({...st, page: 1}));
                    }}>
                    <FirstPageIcon
                        fontSize="inherit" />
                </IconButton>
                <IconButton
                    className="outline-none text-accent2 -mx-2"
                    onClick={pageBefore}>
                    <ArrowLeftIcon
                        fontSize="inherit" />
                </IconButton>
                <input
                    ref={pageRef}
                    defaultValue={paginationSettings.page}
                    onKeyDown={onlyNumbers}
                    onBlur={(e) => {
                        let value = 1; 
                        if (e.target.value === '') {
                            value = 1;
                        } else if (Number(e.target.value) > paginationData.totalPages) {
                            value = paginationData.totalPages;
                        } else {
                            value = e.target.value;
                        }

                        e.target.value = value;
                        setPaginationSettings((st) => ({...st, page: Number(value)}));
                    }}
                    className="w-10 text-center border-1 border-customGray focus:outline-none" />
                <IconButton
                    className="outline-none text-accent2 -mx-2"
                    onClick={nextPage}>
                    <ArrowRightIcon
                        fontSize="inherit" />
                </IconButton>
                <IconButton
                    className="outline-none text-accent2 -mx-2"
                    onClick={() => {
                        pageRef.current.value = paginationData.totalPages;
                        setPaginationSettings((st) => ({...st, page: paginationData.totalPages}));
                    }}>
                    <LastPageIcon
                        fontSize="inherit" />
                </IconButton>
                <p>of {paginationData?.totalPages}. &nbsp; Show:</p>
                <input
                    ref={itemsAtPageRef}
                    defaultValue={paginationSettings.perPage}
                    className="w-10 text-center border-1 border-customGray focus:outline-none"
                    onBlur={(e) => {
                        let value = 1; 
                        if (e.target.value === '') {
                            value = 10;
                        } else {
                            value = e.target.value;
                        }

                        e.target.value = value;
                        pageRef.current.value = 1;
                        setPaginationSettings((st) => ({...st, page: 1, perPage: Number(value)}));
                        dispatch.ui.setPaginationItemsPerPage(Number(value));
                    }} />
                <p>rows at a time.</p>
            </div>}

            {/* {!loading && data.length > 0 && <div className="full flex justify-center items-center mt-4 gap-2">
                <IconButton
                    className="outline-none text-accent2"
                    onClick={pageBefore}>
                    <ArrowLeftIcon
                        fontSize="inherit" />
                </IconButton>
                {Array(paginationData?.totalPages).fill(1).map((item, index) => 
                    <Button
                        key={`page${index}`}
                        className={`${paginationSettings.page === (index + 1) && 'text-accent2 bg-primary font-bold'} outline-none`}
                        size="small"
                        onClick={() => setPaginationSettings((st) => ({...st, page: index + 1}))}>
                        {index + 1}
                    </Button>)}
                <IconButton
                    className="outline-none text-accent2"
                    onClick={nextPage}>
                    <ArrowRightIcon
                        fontSize="inherit" />
                </IconButton>
            </div>} */}
        </div>
    );
});

export default CustomTable;