const auth = {
    state: {
        headerTitle: 'Manager',
        isSidenavOpen: false,
        notificationsCount: 0,
        paginationItemsPerPage: 10
    },
    reducers: {
        setHeaderTitle(state, payload) {
            return {
                ...state,
                headerTitle: payload
            };
        },
        setIsSidenavOpen(state, payload) {
            localStorage.setItem('fmIsOpenMenu', JSON.stringify(payload));
            return {
                ...state,
                isSidenavOpen: payload
            };
        },
        seNotificationsCount(state, payload) {
            return {
                ...state,
                notificationsCount: payload
            };
        },
        setPaginationItemsPerPage(state, payload) {
            localStorage.setItem('fmPaginationItemsPerPage', JSON.stringify(payload));
            return {
                ...state,
                paginationItemsPerPage: payload
            }
        }
    }
};

export default auth;