import React, { forwardRef, memo } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Slide from '@material-ui/core/Slide';

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const CustomDialog = memo(({children, isOpen, handleAccept, handleClose, cancelButton = 'Cancel', acceptButton = 'Accept', title = '', maxWidth = 'md', showButtons = true}) => {
  return (
    <Dialog
      disableBackdropClick
      disableEscapeKeyDown
      open={isOpen}
      TransitionComponent={Transition}
      keepMounted
      onClose={handleClose}
      maxWidth={maxWidth}
      fullWidth
      aria-labelledby="alert-dialog-slide-title"
      aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle>{title}</DialogTitle>
        <DialogContent>
          {children}
        </DialogContent>
        {showButtons && <DialogActions>
          <Button
            onClick={handleClose}
            variant="contained"
            type="button"
            className="mr-4 font-bold text-white outline-none">
            {cancelButton}
          </Button>
          <Button
            onClick={handleAccept}
            variant="contained"
            className="bg-primary px-12 font-bold outline-none">
            {acceptButton}
          </Button>
        </DialogActions>}
      </Dialog>
  );
});

export default CustomDialog;
