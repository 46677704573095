import React from 'react';
import { ReactComponent as FtLogo } from '../../../assets/svg/logo.svg';

import './SplashScreen.css';

const SplashScreen = () => {
    return (
        <div className="min-w-screen min-h-screen flex items-center justify-center bg-accent2 text-white">
            <FtLogo className="ft-logo" />
        </div>
    );
};

export default SplashScreen;