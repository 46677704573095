import { ApolloClient, InMemoryCache, createHttpLink, split } from '@apollo/client';
import { getMainDefinition } from '@apollo/client/utilities';
import { setContext } from '@apollo/client/link/context';
import { WebSocketLink } from '@apollo/client/link/ws';

const wsLink = new WebSocketLink({
  uri: process.env.REACT_APP_API_URL_WS,
  options: {
    reconnect: true,
    connectionParams: () => {
      const token = JSON.parse(localStorage.getItem('fmAuth'))?.accessToken;
      return {
        authorization: `Bearer ${token}`
      }
    }
  }
});

const httpLink = createHttpLink({
  uri: process.env.REACT_APP_API_URL,
});

const authLink = setContext((_, { headers }) => {
  // get the authentication token from local storage if it exists
  const authInfo = JSON.parse(localStorage.getItem('fmAuth'));

  // return the headers to the context so httpLink can read them
  return {
    headers: {
      ...headers,
      authorization: authInfo ? `Bearer ${authInfo.accessToken}` : "",
    }
  }
});

const defaultOptions = {
  watchQuery: {
    fetchPolicy: 'no-cache',
    errorPolicy: 'ignore',
  },
  query: {
    fetchPolicy: 'no-cache',
    errorPolicy: 'all',
  },
};

const link = split(
  // split based on operation type
  ({ query }) => {
    const { kind, operation } = getMainDefinition(query);
    return kind === 'OperationDefinition' && operation === 'subscription';
  },
  wsLink,
  authLink.concat(httpLink),
);

export const client = new ApolloClient({
  link,
  cache: new InMemoryCache({
    resultCaching: false,
  }),
  defaultOptions
});