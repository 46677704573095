import { useState } from 'react';

const useLicenseKeyMask = () => {
    const [ inputOldValue, setInputOldValue ] = useState();
    const [ inputOldCursor, setInputOldCursor ] = useState();
    const inputRegex = /[a-zA-Z0-9]/gm;
    const maskSeparator = '-';

    const checkSeparator = (position, interval) => Math.floor(position / (interval + 1));

    const mask = (value, limit, separator) => {
        var output = [];

        for (let i = 0; i < value.length; i++) {
            if ( i !== 0 && i % limit === 0) {
                output.push(separator);
            }
            
            output.push(value[i]);
        }
        
        return output.join("");
    };
    
    const unmask = (value) => value.replace(/[-]/g, '');

    const onKeyDown = (e) => {
        const el = e.target;
        setInputOldValue(() => el.value);
        setInputOldCursor(() => el.selectionEnd);

        if (!e.key.match(inputRegex)) e.preventDefault();
    };

    const onInput = (e) => {
        const el = e.target;
        let newValue = unmask(el.value);
        let newCursorPosition;
        
        newValue = mask(newValue, 4, maskSeparator);
        
        newCursorPosition = 
            inputOldCursor - checkSeparator(inputOldCursor, 4) + 
            checkSeparator(inputOldCursor + (newValue.length - inputOldValue.length), 4) + 
            (unmask(newValue).length - unmask(inputOldValue).length);
        
        
        el.value = newValue !== "" ? newValue : "";
        /* if (newValue.match(inputRegex)) {
        } else {
            el.value = inputOldValue;
            newCursorPosition = inputOldCursor;
        } */
        
        el.setSelectionRange(newCursorPosition, newCursorPosition);
    };

    return { onKeyDown, onInput };
};

export default useLicenseKeyMask;