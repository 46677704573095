import React, { Suspense, lazy } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Switch, Route, Redirect } from 'react-router-dom';
import './assets/styles/tailwind.css';
import { SplashScreen } from './components/generic';

/* Pages */
const AuthPage = lazy(() => {
  return new Promise((resolve) => {
    setTimeout(() => resolve(import('./pages/Auth/Auth')), 2000);
  })
});
const AdminPage = lazy(() => import('./pages/Admin/Admin'));


function App() {
  const { authenticatedUser } = useSelector((st) => st.auth);
  const dispatch = useDispatch();
  const fmAuth = localStorage.getItem('fmAuth');

  if (authenticatedUser === null && fmAuth) {
    dispatch.auth.setAuthenticatedUser(JSON.parse(fmAuth));
  }

  return (
    <div className="App font-sans">
      <Suspense fallback={<SplashScreen />}>

        {fmAuth
          ? <Switch>
              <Route path="/auth" component={AuthPage} />
              <Route path="/admin" component={AdminPage} />

              <Redirect to="/admin" />
            </Switch>
          : <Switch>
              <Route path="/auth" component={AuthPage} />

              <Redirect to="/auth/sign-in" />
            </Switch>
        }
      </Suspense>
    </div>
  );
}

export default App;
