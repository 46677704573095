import React, { createContext, useState }from 'react';
import {
    IconButton
} from '@material-ui/core';
import {
    Alert,
    AlertTitle
} from '@material-ui/lab';
import {
    Close as CloseIcon
} from '@material-ui/icons';

export const NotificationContext = createContext({});

export const NotificationProvider = ({ children }) => {
    const [ notificationConfig, setNotificationConfig ] = useState({
        type: null,
        title: null,
        msg: null,
        customHtml: null
    }); 

    const showNotification = (type, title, msg) => {
        setNotificationConfig(() => ({type, title, msg}));
        setTimeout(closeNotification, 4000);
    };

    const showCustomNotification = (type, customHtml) => {
        setNotificationConfig((st) => ({...st, type, customHtml}));
        setTimeout(closeNotification, 4000);
    };

    const closeNotification = () => {
        setNotificationConfig(() => ({type: null, title: null, msg: null}))
    };

    return (
        <NotificationContext.Provider value={{showNotification, showCustomNotification}}>
            <div className="notications-container relative min-w-screen min-h-screen">
                {notificationConfig.type && <div className="alert-container right-0 mt-0 mr-4 mt-4 text-white z-50 fixed">
                    <Alert
                        variant="filled"
                        severity={notificationConfig.type}
                        action={
                            <IconButton
                            aria-label="close"
                            color="inherit"
                            size="small"
                            onClick={closeNotification}>
                                <CloseIcon fontSize="inherit" />
                            </IconButton>
                        }>
                        {notificationConfig.customHtml && <AlertTitle>{notificationConfig.customHtml}</AlertTitle>}
                        {notificationConfig.title && <AlertTitle>{notificationConfig.title}</AlertTitle>}
                        {notificationConfig.msg}
                    </Alert>
                </div>}
                {children}
            </div>
        </NotificationContext.Provider>
    );
};