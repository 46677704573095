const specialKeys = [
    'Tab',
    'Shift',
    'Control',
    'Alt',
    'Backspace',
    'ArrowLeft',
    'ArrowRight',
];

const useOnlyNumbers = () => {

    const onlyNumbers = (e) => {
        if (specialKeys.includes(e.key) || Number(e.key) || e.key === '0') {
            return;
        }
        
        e.preventDefault();
    };

    return { onlyNumbers };
};

export default useOnlyNumbers;